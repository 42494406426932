import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { useEffect, useState } from 'react';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { ErrorBanner } from '@/components/ErrorBanner';
import { Link } from '@/components/Link';
import { Page } from '@/components/Page';
import { PageBodySkeleton } from '@/components/PageBodySkeleton';
import { TextInput } from '@/components/TextInput';
import { useAppContext } from '@/features/app';
import { useApi } from '@/hooks/useApi';
import {
  Employer,
  EmployerRespirator,
  InitializerAccount,
  ProximaRecord,
  QueryApiResponseData,
  RespiratorMedicalEvaluation,
} from '@/types/api';
import { NAME_SUFFIX_LABELS } from '@/utils/pii';

import { CreateRespiratorFitTestFormData, EmployerStub } from '../types';

import { FitTestDetailsCard } from './FitTestDetailsCard';

export function NewRespiratorFitTestPage() {
  function blankFormData() {
    return {
      emailResultToEmployee: true,
      employerRespirator: null,
      protocol: null,
      quantitativeFitFactor: null,
      respiratorMedicalEvaluation: null,
      respiratorSize: null,
      result: null,
      testDate: '',
    };
  }

  const { currentAccount } = useAppContext();

  const [employer, setEmployer] = useState<EmployerStub | null>(null);

  const employerRespiratorsApiState = useApi<QueryApiResponseData<EmployerRespirator>>({
    disable: employer === null,
    path: `/employer-respirators?employerPrn=${employer?.prn}&sortBy=model`
  });

  const [formData, setFormData] = useState<CreateRespiratorFitTestFormData>(blankFormData());

  const [submitState, setSubmitState] = useState<
    'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS'
  >('UNSUBMITTED');

  useEffect(() => {
    if (currentAccount !== undefined && currentAccount.accountType === 'EMPLOYER') {
      setEmployer(currentAccount);
    }
  }, [currentAccount]);

  return (
    <Page
      breadcrumbs={[
        {
          label: 'Home',
          path: '/',
        },
      ]}
      title="Enter Fit Tests"
    >
      <div className="flex flex-col gap-4">
        {currentAccount === undefined ? (
          <PageBodySkeleton />
        ) : currentAccount.accountType === 'SERVICE_PROVIDER' && (
          <EmployerSelectionCard
            account={currentAccount}
            employer={employer}
            onSelect={(newEmployer) => {
              if (newEmployer !== employer) {
                setEmployer(newEmployer);
                setFormData(blankFormData());
              }
            }}
            submitState={submitState}
          />
        )}
        {employer !== null && (
          <>
            <EmployeeSelectionCard
              employer={employer}
              onSelect={(respiratorMedicalEvaluation) => {
                setFormData({
                  ...formData,
                  respiratorMedicalEvaluation,
                });
              }}
              respiratorMedicalEvaluation={formData.respiratorMedicalEvaluation}
              submitState={submitState}
            />
            {formData.respiratorMedicalEvaluation !== null && (
              <FitTestDetailsCard
                account={currentAccount}
                employerRespiratorsApiState={employerRespiratorsApiState}
                formData={formData}
                setFormData={setFormData}
                setSubmitState={setSubmitState}
                submitState={submitState}
              />
            )}
          </>
        )}
      </div>
    </Page>
  );
}

function EmployeeSelectionCard({
  employer,
  onSelect,
  respiratorMedicalEvaluation,
  submitState,
}: {
  employer: EmployerStub;
  onSelect: (_: RespiratorMedicalEvaluation) => void;
  respiratorMedicalEvaluation: RespiratorMedicalEvaluation | null;
  submitState: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS';
}) {
  const [cachedRecords, setCachedRecords] = useState<ProximaRecord[]>([]);
  const [committedSearch, setCommittedSearch] = useState('');
  const [isManualOpen, setIsManualOpen] = useState(true);
  const [liveSearch, setLiveSearch] = useState('');

  const {data, failed, isLoading } = useApi<QueryApiResponseData<ProximaRecord>>({
    disable: committedSearch === '',
    path: `/records?employerPrn=${employer.prn}&employeeName=${committedSearch}&includeEmployeeDateOfBirth=true&sortBy=employeeLastName&subtype=RESPIRATOR_MEDICAL_EVALUATION`
  });

  useEffect(() => {
    if (!isLoading && !failed) {
      setCachedRecords(data.data);
    }
  }, [data, isLoading, failed]);

  const isOpen = respiratorMedicalEvaluation === null || isManualOpen;
  const entryDisabled = submitState === 'SUBMITTING' || submitState === 'SUCCESS';

  return (
    <Card id="employee-selection">
      <div className="max-w-2xl mx-auto">
        {isOpen ? (
          <div className="flex flex-col gap-6">
            <div className="flex items-start gap-4">
              <div className="grow font-semibold">Select an employee to continue:</div>
              {respiratorMedicalEvaluation !== null && (
                <Link
                  onClick={() => {
                    setIsManualOpen(false);
                  }}
                >
                  Cancel
                </Link>
              )}
            </div>
            <form onSubmit={(evt) => {
              evt.preventDefault();
              setCommittedSearch(liveSearch);
            }}>
              <div className="flex items-center gap-2">
                <TextInput
                  className="grow"
                  onChange={(evt) => {
                    setLiveSearch(evt.target.value);
                  }}
                  placeholder="Employee Name"
                  value={liveSearch}
                />
                <Button type="submit">Search</Button>
              </div>
            </form>
            {committedSearch !== '' && (
              <>
                {isLoading ? (
                  <div>Loading...</div>
                ) : cachedRecords.length === 0 ? (
                  <div>No employees found.</div>
                ) : (
                  <div className="flex flex-col gap-3">
                    {cachedRecords.map((record) => (
                      <div key={record.prn}>
                        <Link
                          onClick={() => {
                            onSelect(record as RespiratorMedicalEvaluation);
                            setCachedRecords([]);
                            setCommittedSearch('');
                            setIsManualOpen(false);
                            setLiveSearch('');
                          }}
                        >
                          {record.employee.lastName}
                          ,{' '}
                          {record.employee.firstName}
                          {record.employee.nameSuffix !== null && (
                            ` ${NAME_SUFFIX_LABELS[record.employee.nameSuffix]}`
                          )}
                        </Link>
                        <EmployeeDateOfBirth isoDob={record.employee.dateOfBirth} />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="flex items-center">
            <div className="grow flex flex-col gap-2">
              <div className="text-sm text-purple-200 font-semibold">EMPLOYEE</div>
              <div>
                {respiratorMedicalEvaluation.employee.lastName},{' '}
                {respiratorMedicalEvaluation.employee.firstName}
                <EmployeeDateOfBirth isoDob={respiratorMedicalEvaluation.employee.dateOfBirth} />
              </div>
            </div>
            {!entryDisabled && (
              <Button
                onClick={() => {
                  setIsManualOpen(true);
                }}
              >
                Change
              </Button>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

function EmployeeDateOfBirth({
  isoDob,
}: {
  isoDob?: string;
}) {
  if (isoDob === undefined) {
    return null;
  }

  return <span className="text-sm">&nbsp;&nbsp;(dob {format(parseISO(isoDob), 'M/d/y')})</span>;
}

function EmployerSelectionCard({
  account,
  employer,
  onSelect,
  submitState,
}: {
  account?: InitializerAccount;
  employer: EmployerStub | null;
  onSelect: (_: Employer) => void;
  submitState: 'UNSUBMITTED' | 'SUBMITTING' | 'ERROR' | 'SUCCESS';
}) {
  const [isManualOpen, setIsManualOpen] = useState(true);

  const { data, failed, isLoading } = useApi<QueryApiResponseData<Employer>>({
    disable: account === undefined,
    path: `/employers?serviceProviderPrn=${account?.prn}&sortBy=name`,
  });

  const isOpen = employer === null || isManualOpen;
  const entryDisabled = submitState === 'SUBMITTING' || submitState === 'SUCCESS';

  return isLoading ? (
    <PageBodySkeleton />
  ) : failed ? (
    <ErrorBanner message="Employers could not be loaded." />
  ) : (
    <Card>
      <div className="max-w-2xl mx-auto">
        <div className={`flex ${isOpen ? 'items-start' : 'items-center'}`}>
          <div className={`grow flex flex-col ${isOpen ? 'gap-6' : 'gap-2'}`}>
            {isOpen ? (
              <>
                <div className="font-semibold">Select an employer to continue:</div>
                <div className="flex flex-col gap-2">
                  {data.data.map((employer) => (
                    <div key={employer.prn}>
                      <Link
                        onClick={() => {
                          onSelect(employer);
                          setIsManualOpen(false);
                        }}
                      >
                        {employer.preferredName}
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="text-sm text-purple-200 font-semibold">EMPLOYER</div>
                <div>{employer.preferredName}</div>
              </>
            )}
          </div>
          {!isOpen && !entryDisabled && (
            <Button
              onClick={() => {
                setIsManualOpen(true);
              }}
            >
              Change
            </Button>
          )}
          {isOpen && employer !== null && (
            <Link
              onClick={() => {
                setIsManualOpen(false);
              }}
            >
              Cancel
            </Link>
          )}
        </div>
      </div>
    </Card>
  );
}
